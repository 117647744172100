<template>
  <el-dialog :title="options.title" :visible.sync="visible" :close-on-click-modal="false" width="800px">
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取 消</el-button>
      <el-button type="primary" @click="confirmForm">确 定</el-button>
    </span>

    <el-form ref="form" :model="form" label-width="100px">

      <el-form-item label="采集名称">
        <el-input v-model="form.name"></el-input>
      </el-form-item>

      <el-form-item label="数据源">
        <el-select v-model="form.db_source_id">
          <el-option v-for="o in db_source" :key="o.id" :label="o.name" :value="o.id" />
        </el-select>
      </el-form-item>

      <el-form-item label="挂载元数据">
        <el-cascader v-model="form.mountMetaPath" :options="metaTree" style="width: 100%;">
          <template slot-scope="{ data }">
            <span>
              <i v-if="data.model_icon" :class="data.model_icon" style="margin-right: 3px"></i>
              {{ data.label }}
            </span>
          </template>
        </el-cascader>
      </el-form-item>

      <el-form-item label="采集库">
        <el-input v-model="form.schema"></el-input>
      </el-form-item>

      <el-form-item label="描述">
        <el-input v-model="form.description" type="textarea" resize="none" :rows="3" />
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>

import _ from 'lodash';
export default {
  data() {
    return {
      visible: false,
      options: {},
      form: {},
      defaultForm: {},

      db_source: [],

      metaTree: [],
    }
  },

  methods: {
    async init() {
      const { list: db_source } = await this.$api.execute('db_source.list');
      this.db_source = db_source;

      const { list: meta_group } = await this.$api.execute('meta_group.list');
      const { list: meta } = await this.$api.execute('meta.list', { mount_pos: 1 });

      const buildTree = (list, parent_node_key) => {
        return _(list).filter({ parent_node_key}).map(o => {          
          const children = buildTree(list, o.node_key);
          return _.size(children) ? _.assign({}, o, { children }) : (_.startsWith(o.node_key, 'META:') ? o : null)
        }).compact().value();
      }

      this.metaTree = buildTree([]
        .concat(_.map(meta_group, o => _.assign({}, o, { node_key: `META_GROUP:${o.id}`, parent_node_key: o.parent_id ? `META_GROUP:${o.parent_id}` : null, label: o.name, value: `META_GROUP:${o.id}` })))
        .concat(_.map(meta, o => _.assign({}, o, { node_key: `META:${o.id}`, parent_node_key: `META_GROUP:${o.group_id}`, label: o.name, value: o.id })))
      , null);


      if(this.form.mount_meta_id) {
        const mapPath = p => o => {
          const current = _.assign({}, o, {
            path: p ? [...p.path, o.value] : [o.value],
          });
          const children = _(o.children).map(mapPath(current)).flatten().value()
          return [current, ...children]
        }
        this.form.mountMetaPath = _.find(_(this.metaTree).map(mapPath()).flatten().value(), { value: this.form.mount_meta_id })?.path || [];
      }
    },
    show(initForm, options = {}) {
      this.form = _.assign({}, initForm, {});
      this.options = options;
      this.init();
      this.visible = true;
    },

    async confirmForm() {
      this.form.mount_meta_id = _.last(this.form.mountMetaPath);
      try {
        if(this.form.id) {
          await this.$api.execute('meta_fetch.update', this.form.id, _.omit(this.form, ['id']));
        } else {
          await this.$api.execute('meta_fetch.create', this.form);
        }
        this.$emit('success')
        this.visible = false;
      } catch(e) {
        console.log({ e })
      }
    }
  }
}
</script>