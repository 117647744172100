<template>
  <div>
    <el-button style="margin-bottom: 15px;" type="primary" @click="createRecord">添加</el-button>
    <el-table v-loading="loading" :data="tableData" border height="calc(100vh - 400px)">
      <el-table-column prop="name" label="采集名称" width="180" />
      <el-table-column prop="db_source_name" label="数据源" width="100" />
      <!-- <el-table-column prop="host" label="挂载路径" min-width="180" /> -->
      <el-table-column prop="description" label="描述" min-width="180" />
      <el-table-column prop="create_time" label="创建时间" width="140" />
      <el-table-column prop="update_time" label="更新时间" width="140" />
      <el-table-column label="操作" width="200">
        <template slot-scope="{row}">
          <el-button @click="$router.push(`${$route.path}/${row.id}`)" type="text" size="small">查看</el-button>
          <el-button v-if="row.last_record_status === 1" @click="start(row, 1)" type="text" size="small">停止采集</el-button>
          <el-button v-else @click="start(row)" type="text" size="small">立即采集</el-button>
          <el-button @click="updateRecord(row)" type="text" size="small">编辑</el-button>
          <el-button @click="removeRecord(row)" type="text" size="small">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      style="margin-top: 20px; text-align: right;"
      :current-page.sync="current"
      :page-size.sync="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>

    <edit-form ref="editForm" @success="refresh" />
  </div>
</template>
<script>
import _ from 'lodash';
import EditForm from './EditForm';
export default {
  components: { EditForm },
  data() {
    return {
      tableData: [],
      current: 1,
      pageSize: 10,
      total: 0,
      loading: false,
    }
  },
  watch: {
    current() {
      this.refresh()
    },
    pageSize() {
      this.refresh()
    }
  },
  created() {
    this.refresh()
  }, 
  methods: {
    async refresh() {
      console.log('refresh')
      try {
        this.loading = true;
        const res = await this.$api.execute('meta_fetch.list', _.assign({}, _.pick(this, ['current', 'pageSize'])));
        this.tableData = res.list;
        this.total = res.count;
        this.loading = false;
      } catch(e) {
        this.loading = false;
        this.$message.error(e.message);
      }
    },

    createRecord() {
      this.$refs.editForm.show({ }, { title: '新增元数据采集' });
    },
    updateRecord(obj) {
      this.$refs.editForm.show(obj, { title: '编辑元数据采集' });
    },
    async removeRecord(obj) {
      try {
        await this.$confirm('此操作将永久删除该记录, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        });

        await this.$api.execute('meta_fetch.remove', obj.id)

        this.refresh();
        this.$message({ type: 'success', message: '删除成功!' });
      } catch(e) {
        if(e !== 'cancel') { 
          this.$message.error(e.message);
        }
      }
    },

    async start(obj, stop = false) {
      try {
        await this.$api.execute('task.start', obj.task_id, stop);
        this.refresh()
        this.$message.success('操作成功')
      } catch(e) {
        this.$message.error(e.message);
      }
    },
  }
}
</script>